import { openPrivacyCenter } from 'components/lib/Sourcepoint';
import Link from 'components/UI/Link/Link';
import { generateWeakKey } from 'utils/idGenerator';
import { linkUrl } from 'utils/url';
import type { MenuItemProps, WidgetMenuProps } from './widget-menu-default';
import styles from './widget-menu-footer.module.scss';

const MenuLink = ({ menuItem }: { menuItem: MenuItemProps }) => (
  <Link
    noFollow={menuItem?.noFollow}
    className={styles['footer-nav-link']}
    href={menuItem.type !== 'space' ? linkUrl(menuItem.url) : '#'}
    target={menuItem.target ? menuItem.target : ''}
  >
    {menuItem.title}
  </Link>
);

const MenuItem = ({ menuItem }: { menuItem: MenuItemProps }) => (
  <>
    {!!menuItem.url && (
      <>
        <MenuLink menuItem={menuItem} />
        {menuItem.children?.map((menuItem: MenuItemProps) => {
          if (menuItem.url.toLowerCase().includes('privacymanager')) {
            return (
              <button
                className={styles['footer-nav-link']}
                key={generateWeakKey(menuItem, menuItem.title)}
                onClick={openPrivacyCenter}
              >
                {menuItem.title}
              </button>
            );
          }
          return <MenuLink key={generateWeakKey(menuItem, menuItem.title)} menuItem={menuItem} />;
        })}
      </>
    )}
  </>
);

const WidgetMenuFooter = ({ context }: { context: WidgetMenuProps }) => (
  <nav className={styles['footer-nav']}>
    {context.model?.menu.map((menuItem) => {
      return (
        <div key={generateWeakKey(menuItem, menuItem.title)}>
          <MenuItem menuItem={menuItem} />
        </div>
      );
    })}
  </nav>
);

export default WidgetMenuFooter;
